import { Cookies } from 'react-cookie';

export const getUrlParamLegacyBrowsers = (name, queryString) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(queryString);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const setUtmParamCookies = () => {
    if (typeof window !== 'undefined') {
        const queryString = window.location.search;
        if (!queryString.length) {
            return;
        }

        const cookies = new Cookies();
        const from = getUrlParamLegacyBrowsers('from', queryString);
        const gclid = getUrlParamLegacyBrowsers('gclid', queryString);
        const utmSource = getUrlParamLegacyBrowsers('utm_source', queryString);
        const utmMedium = getUrlParamLegacyBrowsers('utm_medium', queryString);
        const utmCampaign = getUrlParamLegacyBrowsers('utm_campaign', queryString);
        const utmContent = getUrlParamLegacyBrowsers('utm_content', queryString);
        const utmTerm = getUrlParamLegacyBrowsers('utm_term', queryString);

        if (from) {
            cookies.set('referralId', from);
        }
        if (gclid) {
            cookies.set('gclid', gclid);
        }
        if (utmSource) {
            cookies.set('utm_source', utmSource);
        }
        if (utmMedium) {
            cookies.set('utm_medium', utmMedium);
        }
        if (utmCampaign) {
            cookies.set('utm_campaign', utmCampaign);
        }
        if (utmContent) {
            cookies.set('utm_content', utmContent);
        }
        if (utmTerm) {
            cookies.set('utm_term', utmTerm);
        }
    }
};
